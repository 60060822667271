export const toursList = [
    {
        id:1,
        title: "Desert Safari, Camel Ride & Al Khamya Camp",
        duration: "4 - 7 hours",
        rating: 4.5,
        reviews: "6,202",
        priceFrom: 35.44,
        image: "/tours/dubai1.jpg",
    },
    {
        id:2,
        title: "Safari, Quad Bike, Camel Ride & Buffet Dinner",
        duration: "7 hours",
        rating: 4.5,
        reviews: "2,425",
        priceFrom: 52.96,
        image: "/tours/dubai2.jpg",
    },
    {
        id:3,
        title: "Safari, Quad Bike & Sandboarding",
        duration: "5 hours",
        rating: 4,
        reviews: "1,002",
        priceFrom: 34.21,
        image: "/tours/dubai3.jpg",
    },
    {
        id:4,
        title: "Desert Quad Bike , Sand Surf , BBQ",
        duration: "6 hours",
        rating: 3.5,
        reviews: "87",
        priceFrom: 28.98,
        image: "/tours/dubai4.jpg",
    },
    {
        id:5,
        title: "Red Dune Safari, Camel Riding",
        duration: "5 - 8 hours",
        rating: 4.5,
        reviews: "14,548",
        priceFrom: 31.98,
        image: "/tours/dubai5.jpg",
    },
    {
        id:6,
        title: "Half Day Desert & Quad Bike Option",
        duration: "4 hours",
        rating: 5,
        reviews: "5,580",
        priceFrom: 25.98,
        image: "/tours/dubai6.jpg",
    },
    {
        id:7,
        title: "Evening Red Dunes With Buffer Dinner",
        duration: "7 hours",
        rating: 4.5,
        reviews: "785",
        priceFrom: 53.32,
        image: "/tours/dubai7.jpg",
    },
    {
        id:8,
        title: "Premium Red Dunes, Camel Safari , BBQ",
        duration: "7 hours",
        rating: 4.5,
        reviews: "8,412",
        priceFrom: 41.96,
        image: "/tours/dubai8.jpg",
    },
    {
        id:9,
        title: "Desert Safari With VIP BBQ and Optional Quad Bike",
        duration: "10 - 14 hours",
        rating: 5,
        reviews: "21,541",
        priceFrom: 78.90,
        image: "/tours/dubai9.jpg",
    },
    {
        id:10,
        title: "Evening Desert With Live Song",
        duration: "4 - 6 hours",
        rating: 4,
        reviews: "8,412",
        priceFrom: 35.98,
        image: "/tours/dubai10.jpg",
    },
    {
        id:11,
        title: "Desert Safari, Sand Boarding & Camp BBQ",
        duration: "4 - 6 hours",
        rating: 3,
        reviews: "5,751",
        priceFrom: 38.26,
        image: "/tours/dubai11.jpg",
    },
    {
        id:12,
        title: "Adventure Red Dune Desert Safari",
        duration: "4 - 7 hours",
        rating: 4.5,
        reviews: "6,301",
        priceFrom: 41.44,
        image: "/tours/dubai12.jpg",
    },
    {
        id:13,
        title: "Red Dune Desert Morning Adventure with Sand Boarding",
        duration: "3 - 4 hours",
        rating: 4.8,
        reviews: "4,412",
        priceFrom: 31.44,
        image: "/tours/dubai13.jpg",
    },
    {
        id:14,
        title: "Desert Day Trip with Camel Ride, Dancers & BBQ",
        duration: "6 hours",
        rating: 5,
        reviews: "925",
        priceFrom: 92.27,
        image: "/tours/dubai14.jpg",
    },
    {
        id:15,
        title: "SandBoarding & BBQ with View of Sunset",
        duration: "5 hours",
        rating: 4,
        reviews: "7,772",
        priceFrom: 44.21,
        image: "/tours/dubai15.jpg",
    },
    {
        id:16,
        title: "Evening Dune Buggy and Desert Adventure",
        duration: "4 - 6 hours",
        rating: 4.6,
        reviews: "15,879",
        priceFrom: 44.98,
        image: "/tours/dubai16.jpg",
    },
    {
        id:17,
        title: "Morning ATV Adventure",
        duration: "1 - 6 hours",
        rating: 4.8,
        reviews: "6,245",
        priceFrom: 21.98,
        image: "/tours/dubai17.jpg",
    },
    {
        id:18,
        title: "Sunrise Desert Jeep Safari with Wildlife",
        duration: "4 hours",
        rating: 4.1,
        reviews: "12,102",
        priceFrom: 18.21,
        image: "/tours/dubai18.jpg",
    },
    {
        id:19,
        title: "Arabian Dune Safari",
        duration: "5 hours",
        rating: 4.4,
        reviews: "1,233",
        priceFrom: 48.00,
        image: "/tours/dubai19.jpg",
    },
    {
        id:20,
        title: "Camel Safari with Overnight Camp",
        duration: "17 hours",
        rating: 5,
        reviews: "6,870",
        priceFrom: 161.12,
        image: "/tours/dubai20.jpg",
    },
    {
        id:21,
        title: "Morning Safari by Vintage Car",
        duration: "4 - 5 hours",
        rating: 3.9,
        reviews: "32",
        priceFrom: 78.90,
        image: "/tours/dubai21.jpg",
    },
    {
        id:22,
        title: "Desert Safari, BBQ",
        duration: "6 hours",
        rating: 4.1,
        reviews: "2,987",
        priceFrom: 31.98,
        image: "/tours/dubai22.jpg",
    },
    {
        id:23,
        title: "Hot Air Baloon Tour Over the Dubai Desert",
        duration: "4 hours",
        rating: 4.5,
        reviews: "7,751",
        priceFrom: 321.26,
        image: "/tours/dubai23.jpg",
    },
    {
        id:24,
        title: "Desert Safari with Burj Khalifa",
        duration: "7 hours",
        rating: 4.6,
        reviews: "17,301",
        priceFrom: 58.44,
        image: "/tours/dubai24.jpg",
    }, 
]

export const languages = [
    {
        id: 1,
        value: "English (US)",
    },
    {
        id: 2,
        value: "English (UK)",
    },
    {
        id: 3,
        value: "Arabic (Gulf)",
    },
    {
        id: 4,
        value: "Arabic (Egypt)",
    },
    {
        id: 5,
        value: "Arabic (Iraq)",
    },
    {
        id: 6,
        value: "Deutsch (Deutschland)",
    },
    {
        id: 7,
        value: "Deutsch (Schwiz)",
    },
    {
        id: 8,
        value: "Espanol",
    },
    {
        id: 9,
        value: "Portugues",
    },
    {
        id: 10,
        value: "Francais",
    },
    {
        id: 11,
        value: "Turkish",
    },
    {
        id: 12,
        value: "Persian",
    },
    {
        id: 13,
        value: "Kurdi",
    },
    {
        id: 14,
        value: "Chinees",
    },
]

export const currencies = [
    {
        id: 1,
        value: "UAE (Dirham)"
    },
    {
        id: 2,
        value: "Euro"
    },
    {
        id: 3,
        value: "US Dollar"
    },
    {
        id: 4,
        value: "Swiss Franc (CHF)"
    },
    {
        id: 5,
        value: "British Pound"
    },
    {
        id: 6,
        value: "Egyption Pound",
    },
    {
        id: 7,
        value: "Iraqi Dinar",
    },
    {
        id: 8,
        value: "Turkish Lira",
    },
    {
        id: 9,
        value: "Hong Kong Dollar",
    },
    {
        id: 10,
        value: "Canadian Dollar",
    },
]